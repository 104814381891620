import React, { useEffect, useState } from 'react';

import './ViewGuests.css';
import { Link, useHistory } from 'react-router-dom';

import axios from '../../../../axios';
import $ from 'jquery';

const ViewGuests = () => {

    const history = useHistory();

    const [ Guests, setGuests ] = useState([]);

    useEffect(
        () => {

            if ( localStorage.getItem('DeviceID') === undefined || localStorage.getItem('DeviceID') === null )
            {
        
                history.replace('/attdevicesetup');
        
            }

            GetGuests();

        }, [history]
    );

    const GetGuests = () => {

        const Data = new FormData();
        Data.append('location', parseInt(localStorage.getItem('DeviceLocation')));
        axios.post('/getallregisteredguestslocationwise', Data).then( response => {

            setGuests( response.data );

        } ).catch( err => {

            console.log( err );

        } );

    }

    return (
        <>
            <div className="ViewGuests">
                <div className="ViewGuests-content">
                    
                    <div className="d-flex align-items-center justify-content-between mb-5">
                        <h3 className="mb-0">Guests</h3>
                        <Link to="/attrgstrguest" className="btn btn-info">Register New Guest</Link>
                    </div>

                    {
                        Guests.length === 0
                        ?
                        <h3 className="text-center">No Guest Found</h3>
                        :
                        Guests.map(
                            ( val, index ) => {

                                return (
                                    <div className="d-flex align-items-center gests" key={ index }>
                                        <div className="index">
                                            <span> { index + 1 } </span>
                                        </div>
                                        <div className="colum">
                                            <span>Name</span>
                                            <p className="mb-0"> { val.guest_name } </p>
                                        </div>
                                        <div className="colum">
                                            <span>Meeting With</span>
                                            <p className="mb-0">{ val.meeting_person }</p>
                                        </div>
                                        <div className="colum">
                                            <span>Meeting Time</span>
                                            <p className="mb-0">{ val.meeting_time }</p>
                                        </div>
                                    </div>
                                )
                                
                            }
                        )
                    }
                    <Link to="/attdashboard" className="text-center d-block mt-3 mb-0 text-dark">Go To Dashboard</Link>
                </div>
            </div>
        </>
    )

}

export default ViewGuests;