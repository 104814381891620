import React, { useEffect, useState } from 'react';

import './Operations.css';
import axios from '../../../../axios';
import Loading from '../../Components/UI/Loading/Loading';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Operations = () => {

    const history = useHistory();

    const [ EmployeeeId, setEmployeeeId ] = useState( { id: 0 } );

    const [ StartShift, setStartShift ] = useState( false );
    const [ EndShift, setEndShift ] = useState( false );
    const [ StartBreak, setStartBreak ] = useState( false );
    const [ EndBreak, setEndBreak ] = useState( false );

    const [ StartLoading, setStartLoading ] = useState( true );

    useEffect(
        () => {

            let empID = window.location.href.split('=')[1].toString().split('&').shift();

            if ( localStorage.getItem('empID') )
            {

                if ( localStorage.getItem('empID') !== empID )
                {
                    history.replace('/atthome');
                }

            }else
            {

                history.replace('/atthome');

            }

            if ( window.location.href.split('/').pop().split('?').shift() === 'operations' )
            {
                setTimeout(() => {
                
                    goToHome();

                }, 10000);
            }

            setEmployeeeId( { id: empID } );
            let empTime = window.location.href.split('=').pop().toString();

            setTimeout(() => {
                let hour = empTime.split(':').shift();

                let min = empTime.split(':')[1].toString();
                let min1 = parseInt(min) + 1;
                let min2 = parseInt(min) + 2;
                let min3 = parseInt(min) + 3;
                let min4 = parseInt(min) + 4;
                let min5 = parseInt(min) + 5;
                let time = hour + ':' + min.toString();
                let time1 = hour + ':' + min1.toString();
                let time2 = hour + ':' + min2.toString();
                let time3 = hour + ':' + min3.toString();
                let time4 = hour + ':' + min4.toString();
                let time5 = hour + ':' + min5.toString();

                const Data = new FormData();
                Data.append('Time', time);
                Data.append('Time1', time1);
                Data.append('Time2', time2);
                Data.append('Time3', time3);
                Data.append('Time4', time4);
                Data.append('Time5', time5);
                Data.append('empID', empID);
                axios.post('/setstatustolog', Data).then(() => {

                    Requests(empID);

                }).catch(error => {

                    console.log(error);

                });
            }, 1000);

        }, [ history ]
    );

    const Requests = ( id ) => {

        const Data = new FormData();
            Data.append('empID', id);

            axios.post( '/checktimein', Data ).then( response => {

                if ( response.data[0] === undefined )
                {

                    setStartLoading( false );

                    setStartShift( false );
                    setEndShift( true );
                    setStartBreak( true );
                    setEndBreak( true );

                }else
                {

                    axios.post('/checktimeout', Data).then( response => {

                        if (response.data[0].time_out === undefined || response.data[0].time_out === null) {

                            axios.post('/checkbreakin', Data).then( response => {

                                if (response.data[0].break_in === undefined || response.data[0].break_in === null) {

                                    setStartLoading( false );
                                    setStartShift(true);
                                    setEndShift(false);
                                    setStartBreak(false);
                                    setEndBreak(true);
        
                                } else {

                                    axios.post('/checkbreakout', Data).then( response => {

                                        if (response.data[0].break_out === undefined || response.data[0].break_out === null) {
                                          
                                            setStartLoading( false );
                                            setStartShift(true);
                                            setEndShift(false);
                                            setStartBreak(true);
                                            setEndBreak(false);
                
                                        } else {
                 
                                            
                                            setStartLoading( false );
                                            setStartShift(true);
                                            setEndShift(false);
                                            setStartBreak(true);
                                            setEndBreak(true);
                
                                        }
                
                                    } ).catch( error => {
                
                                        setStartLoading( false );
                                        toast.dark(error, {
                                            position: 'bottom-center',
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                
                                    } );
        
                                }
        
                            } ).catch( error => {
        
                                setStartLoading( false );
                                toast.dark(error, {
                                    position: 'bottom-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
        
                            } );

                        } else {

                            toast.dark('You are timeout', {
                                position: 'bottom-center',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            setStartLoading( false );

                            setStartShift(true);
                            setEndShift(true);
                            setStartBreak(true);
                            setEndBreak(true);

                        }

                    } ).catch( error => {

                        setStartLoading( false );
                        toast.dark(error, {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                    } );

                }

            } ).catch( error => {

                toast.dark(error, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                progress: undefined,
                });
                setStartLoading( false );

            } );

    }

    const startShift = ( id ) => {

        setStartLoading( true );

        const Data = new FormData();
        Data.append('empID', parseInt(id));

        axios.post( '/timein', Data ).then( response => {

            setStartLoading( false );
            toast.dark('Shift Started', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                $('.modalBtn').trigger('click');
            }, 3000);

            Requests( id );

        } ).catch( error => {

            console.log( error );
            setStartLoading( false );

        } );

    };

    const endShift = ( id ) => {

        setStartLoading( true );

        const Data = new FormData();
        Data.append('empID', parseInt(id));

        axios.post( '/timeout', Data ).then( response => {

            setStartLoading( false );
            toast.dark('Shift End', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            Requests( id );

        } ).catch( error => {

            console.log( error );
            setStartLoading( false );

        } );

    }

    const startBreak = ( id ) => {

        setStartLoading( true );

        const Data = new FormData();
        Data.append('empID', parseInt(id));

        axios.post( '/breakin', Data ).then( response => {

            setStartLoading( false );
            toast.dark('Break Started', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            Requests( id );

        } ).catch( error => {

            console.log( error );
            setStartLoading( false );

        } );

    }

    const endBreak = ( id ) => {

        setStartLoading( true );

        const Data = new FormData();
        Data.append('empID', parseInt(id));

        axios.post( '/breakout', Data ).then( response => {

            setStartLoading( false );
            toast.dark('Break End', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            Requests( id );

        } ).catch( error => {

            toast.dark(error, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setStartLoading( false );

        } );

    }

    const goToHome = () => {
        localStorage.removeItem('empID');
        localStorage.removeItem('empinouttime');
        localStorage.removeItem('empName');
        $('.operationModalClose').trigger('click');
        history.replace('/atthome');
    }

    return (
        <>
            <Loading show={ StartLoading } />
            <ToastContainer />
            <div className="Operations">
                <div className="topbar pl-4">
                    <span style={ { 'fontFamily' : 'Poppins' } }>Welcome { localStorage.getItem('empName') } To { localStorage.getItem('companyName') }</span>
                    <button className="btn logoutBtn" onClick={ goToHome }><i className="las la-sign-out-alt"></i><span className='pl-2'>Logout</span></button>
                </div>
                <div className="timeIn Operation-items">
                    <div className="w-100">
                        <i className="las la-stopwatch"></i>
                        <h3 className='mb-3'>Time In</h3>
                        <button disabled={ StartShift } className="btn btn-success btn-block text-uppercase" onClick={ () => startShift( EmployeeeId.id ) }>Start Shift</button>
                    </div>
                </div>
                <div className="timeOut Operation-items">
                    <div className="w-100">
                        <i className="las la-history"></i>
                        <h3 className='mb-3'>Time Out</h3>
                        <button disabled={ EndShift } className="btn btn-danger btn-block text-uppercase" onClick={ () => endShift( EmployeeeId.id ) }>End Shift</button>
                    </div>
                </div>
                <div className="breakIn Operation-items">
                    <div className="w-100">
                        <i className="las la-hourglass-half"></i>
                        <h3 className='mb-3'>Take Break</h3>
                        <button disabled={ StartBreak } className="btn btn-primary btn-block text-uppercase" onClick={ () => startBreak( EmployeeeId.id ) }>Start Break</button>
                    </div>
                </div>
                <div className="breakOut Operation-items">
                    <div className="w-100">
                        <i className="las la-hourglass-end"></i>
                        <h3 className='mb-3'>Break Over</h3>
                        <button disabled={ EndBreak } className="btn btn-secondary btn-block text-uppercase" onClick={ () => endBreak( EmployeeeId.id ) }>End Break</button>
                    </div>
                </div>

                <button type="button" className="btn btn-primary d-none modalBtn" data-toggle="modal" data-target="#exampleModal">
                    Launch demo modal
                </button>
                
                <div className="modal fade operationsModal" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Today's News</h5>
                                <button type="button" className="close operationModalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" onClick={ goToHome }>OK, Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Operations;