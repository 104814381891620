import React from 'react';

import './Home.css';

const Home = () => {

    return (
        <>
            <div className="Admin_Home">
                <div className="Admin_Home-content">
                    <h1 className="text-center">Admin Panel Home</h1>
                </div>
            </div>
        </>
    )

}

export default Home;