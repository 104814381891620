import React, { useEffect, useState } from 'react';

import './InvtryCategories.css';
import axios from '../../../axios';
import $ from 'jquery';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InvtryCategories = () => {

    const [ Category, setCategory ] = useState(
        {
            CtgryName: '', editCtgryName: '', editID: 0
        }
    );
    const [ Categories, setCategories ] = useState([]);

    useEffect(
        () => {

            GetAllCategories();

        }, []
    )

    // Call on change function to store input field data into usestate()
    const OnChangeHandler = ( e ) => {

        const { name, value } = e.target;
        const setValues = {
            ...Category,
            [name]: value
        }

        setCategory(setValues);

    }

    const GetAllCategories = () => {

        axios.get('/getallinvtrycategories').then( response => {

            setCategories( response.data );

        } ).catch( err => {

            console.log( err );

        } );
    }

    const OnAddCategory = ( e ) => {
        e.preventDefault();

        const Data = new FormData();
        Data.append('CtgryName', Category.CtgryName);
        axios.post('/addinvtrycategory', Data).then( () => {

            GetAllCategories();

        } ).catch( err => {

            console.log( err );

        } );
    }

    const OnEdit = ( id, indexx ) => {

        let category = Categories.filter(
            (val, index, arr) => {
                return index === indexx;
            }
        );

        const setValues = {
            ...Category,
            editCtgryName: category[0].category_name,
            editID: id
        }

        setCategory(setValues);
        $('.editModalBtn').trigger('click');

    }

    const OnDelete = ( id ) => {

        const Data = new FormData();
        Data.append('EditCtgryID', id);
        axios.post('/deleteinvtrycategory', Data).then( () => {

            GetAllCategories();

            setCategory(
                {
                    CtgryName: '', editCtgryName: '', editID: 0
                }
            );
            toast.dark('Category Deleted', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } ).catch( err => {

            console.log( err );

        } );
        
    }

    const updateCategory = ( id ) => {

        const Data = new FormData();
        Data.append('EditCtgryName', Category.editCtgryName);
        Data.append('EditCtgryID', id);
        axios.post('/updateinvtrycategory', Data).then( () => {

            GetAllCategories();

            setCategory(
                {
                    CtgryName: '', editCtgryName: '', editID: 0
                }
            );
            toast.dark('Category Updated', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } ).catch( err => {

            console.log( err );

        } );

    }

    return (
        <>
            <ToastContainer />
            <div className="InvtryCategories">
                <h3>Add New Category</h3>
                <form className="addCategory btn-group" onSubmit={ OnAddCategory }>
                    <input type="text" value={ Category.CtgryName } className="form-control" placeholder="Category Name" name="CtgryName" onChange={ OnChangeHandler } pattern="[a-zA-Z][a-zA-Z\s]*" minLength="3" required />
                    <button className="btn" type="submit">Add Category</button>
                </form>

                <h3>All Categories</h3>
                <div className="categories">
                    {
                        Categories.length === 0
                        ?
                        <h3 className="text-center mb-0">No Category Found</h3>
                        :
                        Categories.map(
                            ( val, index ) => {
                                return (
                                    <div className="d-flex align-items-center border-bottom mb-2" key={index}>
                                        <div className="index"> { index + 1 } </div>
                                        <div>
                                            <span style={{ 'color': 'rgb(128, 128, 128, .5)' }}>Name</span>
                                            <h5> { val.category_name } </h5>
                                        </div>
                                        <div className="ml-auto d-flex align-items-center operations">
                                            <div className="px-3">
                                                <button onClick={ () => OnEdit( val.id, index ) } className="btn" style={{ 'backgroundColor': '#1EC916' }}><i className="las la-edit"></i> Edit</button>
                                            </div>
                                            <div className="px-3">
                                                <button onClick={ () => OnDelete( val.id ) } className="btn" style={{ 'backgroundColor': '#FEC400' }}><i className="las la-trash"></i> Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
                <button type="button" className="btn btn-primary d-none editModalBtn" data-toggle="modal" data-target="#exampleModal">
                    Launch demo modal
                </button>
                <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <input type="text" value={ Category.editCtgryName } className="form-control mb-3" placeholder="Category Name" name="editCtgryName" onChange={ OnChangeHandler } pattern="[a-zA-Z][a-zA-Z\s]*" minLength="3" required />
                                <button data-dismiss="modal" className="btn btn-primary d-block ml-auto" onClick={ () => updateCategory( Category.editID ) }>Update Category</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default InvtryCategories;