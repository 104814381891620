import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './Employement_Requests.css';
import axios from '../../../../../axios';

const Employement_Requests = () => {

    const [ Employee, setEmployee ] = useState([]);
    const [ Search, setSearch ] = useState(
        {
            SrchKey: '', SrchBy: 'name'
        }
    );

    useEffect(
        () => {

            axios.get('/getalltempemployee').then( response => {

                setEmployee( response.data );

            } ).catch( err => {

                console.log( err );

            } );

        }, []
    );

    // Call on change function to store input field data into usestate()
    const OnChangeHandler = ( e ) => {

        const { name, value } = e.target;
        const setValues = {
            ...Search,
            [name]: value
        }

        setSearch(setValues);

    }

    const OnSearchEmployee = ( e ) => {

        e.preventDefault();

        const Data = new FormData();
        Data.append('SearchKey', Search.SrchKey);
        Data.append('SearchBy', Search.SrchBy);

        axios.post('/srchtempemp', Data).then( response => {

            setEmployee( response.data );

        } ).catch( err => {

            console.log( err );

        } )

    }

    return (
        <>
            <div className="Employement_Requests d-center">
                <div className="Employement_Requests-content">

                    <h3>Employment Requests</h3>
                    <form className="search-content" onSubmit={ OnSearchEmployee }>
                            <div className="d-flex align-items-center w-75">
                                <input type="text" className="form-control mr-1" placeholder="Search Keywords" name="SrchKey" onChange={ OnChangeHandler } required />
                                <select className="form-control ml-1" onChange={ OnChangeHandler } name="SrchBy">
                                    <option value="name">Search By</option>
                                    <option value="name">Employee Name</option>
                                    <option value="user_name">User</option>
                                </select>
                            </div>
                            <div className="w-25 text-center">
                                <button className="btn searchBtn btn-dark px-4">Search</button>
                            </div>
                        <div className="w-25 text-center">
                            <Link className="btn btn-info" to={ sessionStorage.getItem('userName') === 'UsmanBadar' ? "/admin_employement_requests/admin_employement_setup" : '/admin_employement_requests' }>Create Employee</Link>
                        </div>
                    </form>

                    <h3 className="text-uppercase" style={ { 'color' : 'rgb(128, 128, 128, .5)' } }>Requests</h3>

                    {
                        Employee.length === 0
                        ?
                        <h3 className="text-center">No Request Found</h3>
                        :
                        Employee.map(
                            ( val, index ) => {

                                const d = new Date();

                                return (
                                    <div className="Requests" key={ index }>
                                        <div className='index'>
                                            { index + 1 }
                                        </div>
                                        <div className='colums'>
                                            <span>Employee Name</span>
                                            <p className="mb-0"> { val.name } </p>
                                        </div>
                                        <div className='colums'>
                                            <span>Created By</span>
                                            <p className="mb-0"> { val.user_name } </p>
                                        </div>
                                        <div className='colums'>
                                            <span>Created Date</span>
                                            <p className="mb-0"> { val.created_at.substring(0,10) } </p>
                                        </div>
                                        <div className='colums text-right'>
                                            <Link className="btn btn-primary btn-sm" to={ "/admin_employement_requests/admin_view_temp_employee/" + val.emp_id }>View Details</Link>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }

                </div>
            </div>
        </>
    )

}

export default Employement_Requests;