import React, { useEffect } from 'react';

import './Dashboard.css';
import { Link, useHistory, Route } from 'react-router-dom';

import DeviceSetup from '../DeviceSetup/DeviceSetup';
import ManualAttendance from '../ManualAttendance/ManualAttendance';
import ViewEmployees from '../ViewEmployees/ViewEmployees';
import EmpDetails from '../ViewEmployees/EmpDetails/EmpDetails';
import Notifications from '../Notifications/Notifications';
import RegisterGuest from '../RegisterGuest/RegisterGuest';
import ViewGuests from '../ViewGuests/ViewGuests';

const Dashboard =() => {

    const history = useHistory();

    useEffect(
        () => {
            
            if ( localStorage.getItem('AttLoginID') === undefined || localStorage.getItem('AttLoginID') === null ) 
            {
                    
                history.replace('/attlogin');
        
            }
            
        }, [ history ]
    )

    const Logout= () => {

        localStorage.removeItem('AttLoginID');
        history.replace('/atthome');

    }

    const dashboardContent =
    <>
        <div className="Dashboard"> 
            <div className="container-fluid pt-5 px-5"> 
                <div className="row pt-5"> 
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4"> 
                        <Link to='/attdevicesetup'> 
                            <div className="divs"> 
                                <i className="las la-tablet"></i> 
                                <h5>Device Setup</h5> 
                            </div> 
                        </Link> 
                    </div> 
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4"> 
                        <Link to="/setattendance"> 
                            <div className="divs"> 
                                <i className="las la-fingerprint"></i> 
                                <h5>Manual Attendance</h5> 
                            </div> 
                        </Link> 
                    </div> 
                    {
                        localStorage.getItem('AccessControl') ? JSON.parse( localStorage.getItem('AccessControl') ).includes(100)
                        ?
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4"> 
                            <Link to='/attviewemps'>
                                    <div className="divs">
                                        <i className="las la-users"></i>
                                        <h5>View Employees</h5>
                                    </div>
                            </Link>
                        </div> 
                        :
                        null
                        :
                        null
                    }
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4"> 
                        <Link to='/attrviewguest'>
                                <div className="divs">
                                    <i className="las la-male"></i>
                                    <h5>Guests</h5>
                                </div>
                        </Link>
                    </div> 
                </div> 
            </div> 
        </div>
    </>;

    return (
        <>
            <div className="topBar"> 
                <div> 
                    <Link to='/attdashboard' className='text-white'>
                        <p className="mb-0">Welcome {localStorage.getItem('AttLoginID')} </p>
                    </Link> 
                </div> 
                <div className='d-flex align-items-center'> 
                    <Link to='/notifications' className="text-white">
                        <i className="las la-bell"></i> 
                    </Link>
                    <span className='px-3'>|</span> 
                    <span style={{ 'fontSize': '15px', 'cursor': 'pointer' }} onClick={Logout}>Logout</span> 
                </div> 
            </div>
            <Route exact path='/attdashboard' render={ () => dashboardContent } />
            <Route exact path='/attdevicesetup' render={ () => <DeviceSetup /> } />
            <Route exact path='/setattendance' render={ () => <ManualAttendance /> } />
            <Route exact path='/attviewemps' render={ () => <ViewEmployees /> } />
            <Route exact path='/attrviewguest' render={ () => <ViewGuests /> } />
            <Route exact path='/attrgstrguest' render={ () => <RegisterGuest /> } />
            <Route exact path='/empdetails/:id' render={ () => <EmpDetails /> } />
            <Route exact path='/notifications' render={ () => <Notifications /> } />
        </>
    )

};

export default Dashboard;