import React, { useEffect } from 'react';

import './AdminModule.css';
import { Route, Switch, useHistory } from 'react-router-dom';
import Sidebar from './Components/SideBar/SideBar';
import TopBar from './Components/TopBar/TopBar';
import Breadcrumbs from './Components/Breadcrumbs/Breadcrumbs';
import Home from './Pages/Home/Home';
import Employement_Requests from './Pages/Employement_Setup/Employement_Requests/Employement_Requests';
import Employement_Form from './Pages/Employement_Setup/EmployeeSetup/EmployeeForm';
import Departments from './Pages/Departments/Departments';
import Designations from './Pages/Departments/Designations/Designations';
import ViewTempEmployee from './Pages/Employement_Setup/Employement_Requests/ViewTempEmployee/ViewTempEmployee';
import ConfirmApproval from './Pages/Employement_Setup/Employement_Requests/ViewTempEmployee/ConfirmApproval/ConfirmApproval';

const AdminModule = () => {

    const history = useHistory();

    useEffect(
        () => {

            if ( sessionStorage.getItem('UserID') === undefined || sessionStorage.getItem('UserID') === null )
            {
                history.replace('/admin_login');
            }

        }, [ history ]
    )

    return (
        <>
            <div className='AdminModule'>
                <Sidebar />

                <div className="Admin_Dashboard_main_content">
                    {/* TopBar Start From Here */}
                        <TopBar />
                    {/* TopBar End here */}
                    <div className="content">
                        <Breadcrumbs />
                        <Switch>
                            <Route exact path='/admin_module' component={ Home } />
                            <Route exact path='/admin_employement_requests' component={ Employement_Requests } />
                            <Route exact path='/admin_employement_requests/admin_employement_setup' component={ Employement_Form } />
                            <Route exact path='/admin_employement_requests/admin_view_temp_employee/:id' component={ ViewTempEmployee } />
                            <Route exact path='/admin_employement_requests/confirmapproval/:id' component={ ConfirmApproval } />
                            <Route exact path='/admin_departments' component={ Departments } />
                            <Route exact path='/admin_departments/admin_designations/:id' component={ Designations } />
                        </Switch>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AdminModule;