import React, { useEffect, useState } from 'react';

import './DeviceSetup.css';
import axios from '../../../../axios';
import { useHistory, Link } from 'react-router-dom';

import Loading from '../../Components/UI/Loading/Loading';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeviceSetup = () => {

    const history = useHistory();

    const [ StartLoading, setStartLoading ] = useState(true);
    const [ Locations, setLocations ] = useState([]);
    const [ Devices, setDevices ] = useState([]);

    useEffect(
        () => {

            setTimeout(() => {
                setStartLoading( false );
            }, 500);

            axios.get('/getalltablocations').then( response => {

                setLocations(response.data);

            } ).catch( error => {

                console.log( error );

            } );

            axios.get('/getallattdevices').then( response => {

                setDevices(response.data);

            } ).catch( err => {

                console.log( err );

            } )

            if ( localStorage.getItem('AttLoginID') === undefined || localStorage.getItem('AttLoginID') === null ) 
            {

                history.replace('/attlogin');

            }

        }, [ history ]
    );

    const OnSelectlocation = ( code ) => {

        setStartLoading( true );

        const Data = new FormData();
        Data.append('DevLocation', code);
        axios.post('/getattdevicebylocation', Data).then(  res => {

            localStorage.setItem('DeviceID', res.data[0].device_code);
            localStorage.setItem('DeviceLocation', code);
            setStartLoading( false );
            toast.dark('Location Saved', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                history.replace('/attdashboard');
            }, 1000);

        }).catch( err => {

            console.log( err );

        } )

    }

    return (
        <>
            <Loading show={ StartLoading } />
            <ToastContainer />
            <div className="DeviceSetup">
                <div className="DeviceSetup-content">
                    <h3 className="mb-4">Select Your Location</h3>
                    {
                        Locations.map(
                            ( val, index ) => {
                                return (
                                    <button onClick={ () => OnSelectlocation( val.location_code ) } className="btn" key={ index }> { val.location_name } </button>
                                )
                            }
                        )
                    }
                    <Link to='/attdashboard' className='text-center d-block mb-0 mt-3 text-dark'>Go To Dashboard</Link>
                </div>

            </div>
        </>
    )

}

export default DeviceSetup;