import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import './SideBar.css';
import $ from 'jquery';

const SideBar = ( props ) => {

    useEffect(
        () => {

            $('.Forms_options').slideUp(0);
            $('.attendance_options').slideUp(0);

        }, []
    );

    const FormsLinks = () => {

        if ($('.forms_link').find('i').hasClass('la-caret-right')) {
            $('.forms_link .la-caret-right').removeClass('la-caret-right').addClass('la-caret-down');
            $('.Forms_options').slideDown();
        } else {
            $('.forms_link .la-caret-down').removeClass('la-caret-down').addClass('la-caret-right');
            $('.Forms_options').slideUp();
        }

    }

    const AttendanceLinks = () => {

        if ($('.attendance_link').find('i').hasClass('la-caret-right')) {
            $('.attendance_link .la-caret-right').removeClass('la-caret-right').addClass('la-caret-down');
            $('.attendance_options').slideDown();
        } else {
            $('.attendance_link .la-caret-down').removeClass('la-caret-down').addClass('la-caret-right');
            $('.attendance_options').slideUp();
        }

    }

    return (
        <>
            {/* style={ { 'width' : props.show ? '100vw' : '0' } } */}
            <div className="Dashboard_sideBar" >

                <div className="Dashboard_logo d-center">
                    <div><h4 className="mb-0 logo">SEABOARD</h4></div>
                    <div><button className="btn btn-sm p-0 m-0 sideBar_bars"><i className="las la-bars"></i></button></div>
                </div>

                <div className="Dashboard_links">
                    <NavLink activeClassName="Dashboard_active" to="/dashboard" className="d-center links">
                        <div className="pr-3"><i className="las la-home"></i></div>
                        <div className="links_txt">Home</div>
                    </NavLink>
                    <NavLink activeClassName="Dashboard_active" to="/news" className="d-center links">
                        <div className="pr-3"><i className="lar la-newspaper"></i></div>
                        <div className="links_txt">News</div>
                    </NavLink>
                    <NavLink activeClassName="Dashboard_active" to="/news" className="d-center links">
                        <div className="pr-3"><i className="lab la-rocketchat"></i></div>
                        <div className="links_txt">Chat</div>
                    </NavLink>
                    <NavLink activeClassName="Dashboard_active" to="/news" className="d-center links">
                        <div className="pr-3"><i className="las la-video"></i></div>
                        <div className="links_txt">Black Board</div>
                    </NavLink>
                    {
                        localStorage.getItem('AccessControl') ? JSON.parse(localStorage.getItem('AccessControl')).includes(500)
                        ?
                        <NavLink activeClassName="Dashboard_active" to="/invtry" className="d-center links" target="_blank">
                            <div className="pr-3"><i className="las la-file-invoice-dollar"></i></div>
                            <div className="links_txt">Inventory</div>
                        </NavLink>
                        :
                        null
                        :
                        null
                    }
                    {/* <div className="d-center links forms_link" onClick={ FormsLinks }>
                        <div className="pr-3"><i className="las la-server"></i></div>
                        <div className="d-flex justify-content-between w-100">
                            <div className="links_txt">Forms</div>
                            <div className="links_txt"><i className="las la-caret-right" style={{ 'fontSize': '12px' }}></i></div>
                        </div>
                    </div>
                    <div className="Forms_options _options">
                        <NavLink activeClassName="Dashboard_active" to="/purchaserequisition" className="d-center links">
                            <div className="pr-3"><i className="las la-hand-holding-usd"></i></div>
                            <div className="links_txt">Purchase Requisition</div>
                        </NavLink>
                        <NavLink activeClassName="Dashboard_active" to="/purchaserorder" className="d-center links">
                            <div className="pr-3"><i className="las la-border-none"></i></div>
                            <div className="links_txt">Purchase Order</div>
                        </NavLink>
                        <NavLink activeClassName="Dashboard_active" to="/news" className="d-center links">
                            <div className="pr-3"><i className="las la-exchange-alt"></i></div>
                            <div className="links_txt">Transfer Form</div>
                        </NavLink>
                        <NavLink activeClassName="Dashboard_active" to="/news" className="d-center links">
                            <div className="pr-3"><i className="las la-percent"></i></div>
                            <div className="links_txt">Disposal Form</div>
                        </NavLink>
                        <NavLink activeClassName="Dashboard_active" to="/news" className="d-center links">
                            <div className="pr-3"><i className="lab la-pagelines"></i></div>
                            <div className="links_txt">Leave Form</div>
                        </NavLink>
                    </div> */}
                    <NavLink activeClassName="Dashboard_active" to="/news" className="d-center links">
                        <div className="pr-3"><i className="lab la-google-drive"></i></div>
                        <div className="links_txt">Drive</div>
                    </NavLink>
                    <NavLink activeClassName="Dashboard_active" to="/help" className="d-center links">
                        <div className="pr-3"><i className="las la-question"></i></div>
                        <div className="links_txt">Help</div>
                    </NavLink>  
                </div>
            </div>
        </>
    )

};

export default SideBar;