import React, { useState } from 'react';

import './Dashboard.css';
import { useHistory, Route } from 'react-router-dom';
import SideBar from './Components/SideBar/SideBar';
import TopBar from './Components/TopBar/TopBar';
import Breadcrumbs from './Components/Breadcrumbs/Breadcrumbs';
import Descussion from './Pages/Descussion/Descussion';
import PurchaseRequisition from './Pages/Forms/PurchaseRequisition/PurchaseRequisition';
import PurchaseOrder from './Pages/Forms/PurchaseOrder/PurchaseOrder';
import Devices from './Pages/Attendance/Devices/Devices';

const Dashboard = () => {

    const history = useHistory();
    const [ ShowBar, setShowBar ] = useState( false );

    setInterval(() => {
        
        if ( localStorage.getItem("LoginID") === undefined || localStorage.getItem("LoginID") === null )
        {
            history.replace('/login');
        }
        
    }, 100);

    const ShowSideBar = () => {

        if ( ShowBar )
        {
            setShowBar( false );
        }else
        {
            setShowBar( true );
        }

    }          

    return (
        <>
            <div className="Dashboard">

                {/* SideBar Start From Here */}
                    <SideBar show={ ShowBar } />
                {/* SideBar End Here */}

                <div className="Dashboard_main_content">
                    {/* TopBar Start From Here */}
                        <TopBar sideBarTrue={ ShowSideBar } />
                    {/* TopBar End here */}
                    <div className="content">
                        <Breadcrumbs />
                        <Route exact path='/dashboard' component={ Descussion } />

                        <Route exact path='/purchaserequisition' component={ PurchaseRequisition } />
                        <Route exact path='/purchaserorder' component={ PurchaseOrder } />

                        <Route exact path='/attdevices' component={ Devices } />
                    </div>
                </div>

            </div>
        </>
    )

};

export default Dashboard;