import React from 'react';

import { useHistory } from 'react-router-dom';

const Logout = () => {

    const history = useHistory();

    if ( localStorage.getItem('LoginID') ) {

        localStorage.removeItem('LoginID');
        localStorage.removeItem('AccessControl');
        localStorage.removeItem('EmpID');
        localStorage.removeItem('EmpImg');

        history.replace('/login');

    } else {

        history.replace('/login');

    }

    return(
        <>
        </>
    )
    
}

export default Logout;