import React, { useEffect, useState } from 'react';

import './Home.css';
import { useHistory } from 'react-router-dom';

import Clock from 'react-digital-clock';
import axios from '../../../../axios';
import Loading from '../../Components/UI/Loading/Loading';

const Home = () => {

    const history = useHistory();

    const [ StartLoading, setStartLoading ] = useState(true); // For show Loading page or hide

    useEffect(
        () => {


            setStartLoading( false ); // set loading to false

            if ( localStorage.getItem('empID') ) // if page get employee id
            {

                history.replace('/operations?id=' + localStorage.getItem('empID') + '&time=' + localStorage.getItem('empinouttime')); // the system will redirect the user to operations page

            }

            if ( localStorage.getItem('DeviceID') === undefined || localStorage.getItem('DeviceID') === null ) // if page fail to get device id
            {
        
                history.replace('/attdevicesetup'); // it will redirect the user to device setup page
        
            }else
            {
                const Data = new FormData();
                Data.append('DevLocation', localStorage.getItem('DeviceID'));

                axios.post('/getattdevicebycode', Data).then(  res => {

                    setStartLoading( false );
                    setInterval(() => { // this function will only run when the url is /atthome
                        if ( window.location.href.split('/').pop() === 'atthome' )
                        {

                            const D = new FormData();
                            D.append('location', localStorage.getItem('DeviceLocation'));
                            axios.post('/getempinout', D).then( response => {

                                if ( response.data[0] !== undefined )
                                {

                                    setStartLoading( true );
                                    const d = new FormData();
                                    d.append('empID', response.data[0].emp_id);
                                    axios.post('/getemployeedetails', d).then( res => {

                                        localStorage.setItem('empID', response.data[0].emp_id);
                                        localStorage.setItem('empinouttime', response.data[0].time); // device_in_out table time column
                                        localStorage.setItem('empName', res.data[0].name);
                                        localStorage.setItem('companyName', res.data[0].company_name);
                                        // the user will redirect after a half second to operations page
                                        setTimeout(() => {
                                            history.replace( '/operations?id=' + response.data[0].emp_id + '&time=' + response.data[0].time );
                                        }, 500);

                                    } ).catch( err => {

                                        console.log( err );

                                    } );

                                }

                            } ).catch( error => {

                                console.log( error );

                            } );
                            
                        }

                    }, 1000);

                    // Device ID
                    localStorage.setItem('DeviceID', res.data[0].device_code);

                }).catch( err => {

                    console.log( err );

                } );
            }

        }, [ history ]
    )

    // redirect the user to login page
    const GoToLogin = () => {

        history.replace('/attlogin');

    }

    return (
        <>
            <Loading show={ StartLoading } />
            <div className="Home" onDoubleClick={ GoToLogin }> 
                <div className="Home_content text-center">
                    <h1 className="mb-0">Seaboard</h1>
                    <div className="d-flex justify-content-between align-items-center px-4 font-weight-bold" style={ { 'fontFamily' : 'JosefinSans', 'fontSize' : '22px' } }>
                        <div>G</div>
                        <div>R</div>
                        <div>O</div>
                        <div>U</div>
                        <div>P</div>
                    </div>
                    <Clock format='hh-mm' />
                </div>
            </div>
        </>
    )

}

export default Home;