import React, { useEffect, useState } from 'react';

import './Login.css';
import axios from '../../../../axios';

import { Link, useHistory } from 'react-router-dom';
import Loading from '../../UI/Loading/Loading';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {

    // To change URL
    const history = useHistory();
    let key = 'real secret keys should be long and random';
    const encryptor = require('simple-encryptor')(key);

    const [ UserData, setUserData ] = useState(
        {
            LoginID: '', LoginPass: ''
        }
    );
    
    // To show loading on true : false condition
    const [ StartLoading, setStartLoading ] = useState(true);

    useEffect( () => {

        setTimeout(() => {
            setStartLoading(false);
        }, 500);

    }, [] )

    // Call on change function to store input field data into usestate()
    const OnChangeHandler = ( e ) => {

        const { name, value } = e.target;
        const setValues = {
            ...UserData,
            [name]: value
        }

        setUserData(setValues);

    }

    // On form submition, the following function call
    const OnUserLogin = ( e ) => {

        e.preventDefault();
        setStartLoading(true);

            axios.get('/authemployee').then( response => {

                for ( let x = 0; x < response.data.length; x++ )
                {
                    // if the password and login id ofthe current index of an array is matched with 
                    // the entered login id and password, the following condition will be true
                    
                    if ( UserData.LoginID === encryptor.decrypt( response.data[x].login_id ) )
                    {

                        if ( UserData.LoginPass === encryptor.decrypt( response.data[x].emp_password ) )
                        {

                            const d = new FormData();
                            d.append('empID', response.data[x].emp_id);
                            axios.post('/getemployee', d).then( res => {

                                toast.dark('Login Success', {
                                    position: 'bottom-center',
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
    
                                setStartLoading(false);

                                localStorage.setItem('EmpID', response.data[x].emp_id);
                                localStorage.setItem('LoginID', encryptor.decrypt( response.data[x].login_id ));
                                localStorage.setItem('EmpImg', response.data[x].emp_image);
                                localStorage.setItem('AccessControl', res.data[0].access);

                                setUserData( { LoginID: '', LoginPass: '' } );
    
                                setTimeout(() => {
                                    history.replace('/login');
                                }, 1000);

                            } ).catch( err => {

                                console.log( err );

                            } );

                            break;
                        }else {

                            setStartLoading(false);

                            setUserData( { LoginID: UserData.LoginID, LoginPass: '' } );
                            toast.dark('Password Not Match', {
                                position: 'bottom-center',
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            break;
                            
                        }
                        
                    }else
                    {
                        setStartLoading(false);
                        setUserData( { LoginID: UserData.LoginID, LoginPass: '' } );
                    }

                }

            } ).catch( error => {

                setStartLoading(false);
                toast.dark(error, {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } );

    }

    if (localStorage.getItem('LoginID')) {
        history.replace('/dashboard');
    };

    return (
        <>
            {/* Login Form Component Start From Here */}
            <ToastContainer />
            <div className="EmpLogin d-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-2"></div>
                        <div className="col-lg-6 col-md-8 login-box">
                            <Loading display={StartLoading} position="absolute" />
                            <div className="col-lg-12 login-key">
                                <Link to="/admin_login" target="_blank">
                                    <i className="las la-key"></i>
                                </Link>
                            </div>
                            <div className="col-lg-12 login-title">
                                LOGIN SEABOARD
                            </div>

                            <div className="col-lg-12 login-form">
                                <div className="col-lg-12 login-form">
                                    <form onSubmit={  OnUserLogin } >
                                        <div className="form-group">
                                            <label className="form-control-label">LOGIN ID</label>
                                            <input value={ UserData.LoginID } onChange={ OnChangeHandler } name="LoginID" type="text" className="form-control" required minLength="3" />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label">PASSWORD</label>
                                            <input value={ UserData.LoginPass } onChange={ OnChangeHandler } name="LoginPass" type="password" className="form-control" required minLength="3" />
                                        </div>

                                        <div className="col-lg-12">
                                            <button type="submit" className="btn btn-outline-primary d-block mx-auto">LOGIN</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Login Form Component End Here */}
        </>
    )

}

export default Login;