import React, { useEffect } from 'react';

import './Inventory.css';
import { Link, Route, useHistory } from 'react-router-dom';

import Inventrylogo from '../../images/Inventry-logo.png';
import $ from 'jquery';

import InvtryCategories from './InvtryCategories/InvtryCategories';

const Inventory = () => {

    const history = useHistory();

    useEffect(
        () => {

            $('.profileAbs').slideToggle(0);
            if ( localStorage.getItem('InvtryLoginID') === undefined || localStorage.getItem('InvtryLoginID') === null )
            {
                history.replace('/invtry_Login');
            }

        }, [ history ]
    );

    const DropDown = () => {

        $('.profileAbs').slideToggle();

    }

    const Logout = () => {

        localStorage.removeItem('InvtryEmpID');
        localStorage.removeItem('InvtryLoginID');
        localStorage.removeItem('InvtryEmpImg');
        history.replace('/invtry_Login');

    }

    return (
        <>
            <div className="Inventory">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <Link className="navbar-brand pl-5" to="#">
                        <img src={ Inventrylogo } className="logo" alt="logo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <Link className="nav-link" to="#">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/invtry_categories">Categories</Link>
                            </li>
                            <li className="nav-item profileDiv" onClick={ DropDown }>
                                <img src={ 'images/employees/' + localStorage.getItem('InvtryEmpImg') } className="EmpImg" alt="employeeImg" />
                                <span> { localStorage.getItem('InvtryLoginID') } </span>
                                <i className="las la-caret-down ml-2"></i>
                                <div className="profileAbs">
                                    <div className="d-flex align-items-center w-100 mb-2">
                                        <i className="las la-user-alt"></i>
                                        <p className="mb-0">Profile</p>
                                    </div>
                                    <div className="d-flex align-items-center w-100 mb-2" onClick={ Logout }>
                                        <i className="las la-sign-out-alt"></i>
                                        <p className="mb-0">Logout</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

            <Route exact path="/invtry_categories" component={ InvtryCategories } />
        </>
    )

}


export default Inventory;