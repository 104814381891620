import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import './SideBar.css';
import $ from 'jquery';

const SideBar = ( props ) => {

    useEffect(
        () => {

            $('.Forms_options').slideUp(0);
            $('.attendance_options').slideUp(0);

        }, []
    );

    const FormsLinks = () => {

        if ($('.forms_link').find('i').hasClass('la-caret-right')) {
            $('.forms_link .la-caret-right').removeClass('la-caret-right').addClass('la-caret-down');
            $('.Forms_options').slideDown();
        } else {
            $('.forms_link .la-caret-down').removeClass('la-caret-down').addClass('la-caret-right');
            $('.Forms_options').slideUp();
        }

    }

    const AttendanceLinks = () => {

        if ($('.attendance_link').find('i').hasClass('la-caret-right')) {
            $('.attendance_link .la-caret-right').removeClass('la-caret-right').addClass('la-caret-down');
            $('.attendance_options').slideDown();
        } else {
            $('.attendance_link .la-caret-down').removeClass('la-caret-down').addClass('la-caret-right');
            $('.attendance_options').slideUp();
        }

    }

    return (
        <>
            {/* style={ { 'width' : props.show ? '100vw' : '0' } } */}
            <div className="Admin_Dashboard_sideBar" >

                <div className="Admin_Dashboard_logo d-center">
                    <div><h4 className="mb-0 logo">ADMIN PANEL</h4></div>
                    <div><button className="btn btn-sm p-0 m-0 sideBar_bars"><i className="las la-bars"></i></button></div>
                </div>

                <div className="Admin_Dashboard_links">
                    <NavLink activeClassName="Admin_Dashboard_active" to="/admin_module" className="d-center links">
                        <div className="pr-3"><i className="las la-home"></i></div>
                        <div className="links_txt">Home</div>
                    </NavLink>
                    <NavLink activeClassName="Admin_Dashboard_active" to="/admin_employement_requests" className="d-center links">
                        <div className="pr-3"><i className="las la-wave-square"></i></div>
                        <div className="links_txt">Employement Requests</div>
                    </NavLink>
                    <NavLink activeClassName="Admin_Dashboard_active" to="/admin_departments" className="d-center links">
                        <div className="pr-3"><i className="las la-list-alt"></i></div>
                        <div className="links_txt">Departments</div>
                    </NavLink>
                </div>
            </div>
        </>
    )

};

export default SideBar;