import React, { useEffect } from 'react';

import './App.css';

import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './Components/EmployeeProtal/Auth/Login/Login';
import Dashboard from './Components/EmployeeProtal/Dashboard/Dashboard';
import Logout from './Components/EmployeeProtal/Auth/Logout/Logout';

// Attendance
import Home from './Components/Attendance/Pages/Home/Home';
import AttDashboard from './Components/Attendance/Pages/Dashboard/Dashboard';
import AttLogin from './Components/Attendance/Pages/Login/Login';
import Operations from './Components/Attendance/Pages/Operations/Operations';

import Inventory from './Components/Inventory/Inventory';
import InvtryLogin from './Components/Inventory/InvtryLogin/InvtryLogin';
import AdminModule from './Components/AdminModule/AdminModule';
import Admin_login from './Components/AdminModule/Admin_login/Admin_login';
import Admin_logout from './Components/AdminModule/Admin_Logout/Admin_Logout';

const App = () => {
    
        return ( 
            <>
                <Switch>
                    
                    {/* 
                        For Employee Portal
                    */}

                    <Redirect exact path='/' to='/login' />
                    <Route exact path='/dashboard' component={ Dashboard } />
                    <Route exact path='/login' component={ Login } />
                    <Route exact path='/logout' component={ Logout } />
                    <Route exact path='/descuss_chat/:id' component={ Dashboard } />

                    <Route exact path='/purchaserequisition' component={ Dashboard } />
                    <Route exact path='/purchaserorder' component={ Dashboard } />

                    <Route exact path='/attdevices' component={ Dashboard } />

                    {/* 
                        For Admin Module
                    */}

                    <Route exact path='/admin_login' component={ Admin_login } />
                    <Route exact path='/admin_logout' component={ Admin_logout } />
                    <Route exact path='/admin_module' component={ AdminModule } />
                    <Route exact path='/admin_employement_requests' component={ AdminModule } />
                    <Route exact path='/admin_employement_requests/admin_employement_setup' component={ AdminModule } />
                    <Route exact path='/admin_employement_requests/admin_view_temp_employee/:id' component={ AdminModule } />
                    <Route exact path='/admin_employement_requests/confirmapproval/:id' component={ AdminModule } />
                    <Route exact path='/admin_departments' component={ AdminModule } />
                    <Route exact path='/admin_departments/admin_designations/:id' component={ AdminModule } />

                    {/* 
                        For Attendance
                    */}
                    <Route exact path='/atthome' component={ Home } />
                    <Route exact path='/attdashboard' component={ AttDashboard } />
                    <Route exact path='/attdevicesetup' component={ AttDashboard } />
                    <Route exact path='/setattendance' component={ AttDashboard } />
                    <Route exact path='/attviewemps' component={ AttDashboard } />
                    <Route exact path='/attrviewguest' component={ AttDashboard } />
                    <Route exact path='/attrgstrguest' component={ AttDashboard } />
                    <Route exact path='/empdetails/:id' component={ AttDashboard } />
                    <Route exact path='/notifications' component={ AttDashboard } />
                    <Route exact path='/operations' component={ Operations } />

                    <Route exact path='/attlogin' component={ AttLogin } />


                    {/* 
                        For Inventory
                    */}
                    <Route exact path='/invtry' component={ Inventory } />
                    <Route exact path="/invtry_categories" component={ Inventory } />

                    <Route exact path='/invtry_Login' component={ InvtryLogin } />

                </Switch>
            </>
        )
}

export default App;